import React from "react"
import classNames from "classnames"
// Form components
import axios from "axios"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Close from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js"
import Button from "components/CustomButtons/Button.js"
// style
import contactDialogStyle from "./contactDialogStyle"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const ContactDialog = withStyles(contactDialogStyle)((props) => {
  const { classes, open, onClose } = props

  const snackDuration = 10000
  const [successSnack, setSuccessSnack] = React.useState(false)
  const [errorSnack, setErrorSnack] = React.useState(false)

  const handleCloseSnacks = (event, reason) => {
    setSuccessSnack(false)
    setErrorSnack(false)
  }

  return (
    <div>
      {/* Success Snackbar */}
      <Snackbar
        open={successSnack}
        autoHideDuration={snackDuration}
        onClose={handleCloseSnacks}
      >
        <Alert onClose={handleCloseSnacks} severity="success">
          Thank you, we will get back to you soon.
        </Alert>
      </Snackbar>
      {/* Error Snackbar */}
      <Snackbar
        open={errorSnack}
        autoHideDuration={snackDuration}
        onClose={handleCloseSnacks}
      >
        <Alert onClose={handleCloseSnacks} severity="error">
          Sorry, Unfortunately, an error occurred. Please try again later.
        </Alert>
      </Snackbar>
      {/* Dialog contact form */}
      <Dialog
        classes={{
          root: classes.modalRoot,
          container: classes.modalContainer,
          paper: classNames(classes.modal, classes.modalLarge),
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="contact-dialog-title"
        aria-describedby="contact-dialog-description"
      >
        <Formik
          initialValues={{
            "form-name": "contact",
            fullName: "",
            email: "",
            message: "",
          }}
          validate={(values) => {
            const errors = {}
            // Full name validation
            if (!values.fullName) {
              errors.fullName = "Required"
            } else if (values.fullName.length < 2) {
              errors.fullName = "must be at least 2 characters"
            }
            // Email validation
            if (!values.email) {
              errors.email = "Required"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address"
            }
            // Message validation
            if (!values.message) {
              errors.message = "Required"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            axios({
              method: "POST",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              data: encode(values),
              url: "/",
            })
              .then(function (response) {
                setSuccessSnack(true)
              })
              .catch(function (error) {
                console.log(error)
                setErrorSnack(true)
              })
              .then(function () {
                setSubmitting(false)
                onClose()
              })
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form
              name="contact"
              data-netlify={true}
              netlify-honeypot="bot-field"
            >
              <DialogTitle
                id="contact-dialog-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={onClose}
                >
                  {" "}
                  <Close className={classes.modalClose} />
                </Button>
                <h3 className={classes.modalTitle}>Contact Us</h3>
              </DialogTitle>

              <div className={classes.placeholder}>
                {isSubmitting && <CustomLinearProgress />}
              </div>

              <DialogContent
                id="contact-dialog-description"
                className={classes.cardContent}
              >
                {/* Field: honey pot */}
                <Field type="hidden" name="form-name" value="contact" />
                {/* Field: honey pot */}
                <div className={classes.hiddenField}>
                  <Field
                    component={TextField}
                    type="text"
                    name="bot-field"
                    label="Don’t fill this out if you’re human"
                    placeholder="Don’t fill this out if you’re human"
                    fullWidth
                  />
                </div>

                <GridContainer>
                  {/* Field: email */}
                  <GridItem xs={12} sm={6} md={6}>
                    <Field
                      component={TextField}
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="john.doe@gmail.com"
                      fullWidth
                    />
                  </GridItem>

                  {/* Field: Full name */}
                  <GridItem xs={12} sm={6} md={6}>
                    <Field
                      component={TextField}
                      type="text"
                      name="fullName"
                      label="Full name"
                      placeholder="John Doe"
                      fullWidth
                    />
                  </GridItem>

                  {/* Field: message */}
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      component={TextField}
                      type="text"
                      name="message"
                      label="Your Message"
                      placeholder="Hello, ..."
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.justifyContentCenter
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Send Message
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
})
export default ContactDialog

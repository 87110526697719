import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

const contactDialog = (theme) => ({
  ...modalStyle(theme),
  cardContent: {
    ...modalStyle(theme).modalBody,
  },
  hiddenField: {
    display: "none",
  },
  placeholder: {
    height: 4,
  },
})

export default contactDialog
